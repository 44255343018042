<template>
  <div>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title
          @click="$router.push('/')"
          class="display-1"
          style="cursor:pointer"
      >
        SimpleTimeCards.com
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="login" text v-if="!loggedIn">
        <span class="mr-2">Login</span>
      </v-btn>
      <span class="subheading mr-0" v-if="loggedIn">{{ fullName }}</span>
      <v-btn @click="admin" text v-if="loggedIn">
        <span class="mr-0">Admin</span>
      </v-btn>
      <v-btn @click="logout" text v-if="loggedIn">
        <span class="mr-2">Logout</span>
      </v-btn>
    </v-app-bar>

    <v-content>
      <v-row fill-height>
        <v-col md12>
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-content>

  </div>
</template>

<script>
import {SHOW_LOGIN} from '../store/store_constants'
import network from './network'

export default {
  name: 'Home',
  components: {},
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn
    },
    fullName() {
      return this.$store.getters.fullName
    }
  },
  methods: {
    login() {
      console.log('Show login screen.')
      this.$store.commit(SHOW_LOGIN, true)
    },
    admin() {
      this.$router.push('/admin') // redirect to admin dashboard
    },
    logout() {
      console.log('Logout from Home screen.')
      network.logout()
    },

    // checkAuth: network.refreshLogin,
    // checkAuth2: network.quickCheck,
    // checkCodes: network.checkCodes,

    titleClick() {
      this.$router.push('/')
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>
